






















































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useUserState, useUserActions } from '@/store';

export default defineComponent({
  name: 'MProgramSettings',
  components: {
    MNameAndMetaSettings: () =>
      import('@/components/molecules/settings/m-name-and-meta-settings.vue'),
    MCheckoutQualificationSettings: () =>
      import('@/components/molecules/settings/m-checkout-qualification-settings.vue'),
    MAddOrganizers: () => import('@/components/molecules/settings/m-add-organizers.vue'),
    MRewardsAndCredentialsManagement: () =>
      import('@/components/molecules/settings/m-rewards-and-credentials-management.vue')
  },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    programId: {
      type: String,
      required: true
    },
    fetchProgram: {
      type: Function,
      default: () => {}
    },
    openCheckoutView: {
      type: Boolean,
      default: false,
      required: false
    },
    isTemplate: {
      type: Boolean,
      default: false,
      required: false
    },
    isDraft: {
      type: Boolean,
      default: false,
      required: false
    },
    programDoc: {
      type: Object,
      default: () => {}
    }
  },
  setup(props: any, ctx: any) {
    const { user }: any = useUserState(['user']);
    const { cloneProgramData }: any = useUserActions(['cloneProgramData']);
    const dialog = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const programIdValue = computed({
      get: () => props.programId,
      set: newVal => {
        console.log(newVal);
      }
    });
    const isAddNameAndMeta = ref(true);
    const isAddCheckoutQualification = ref(false);
    const isAddOrganizers = ref(false);
    const isAddRewardsAndCredentials = ref(false);
    const cloneModal = ref(false);
    const isCloning = ref(false);

    const listItems = ref([
      {
        title: `${props?.isTemplate ? 'Template' : 'Program'} Name and Meta`,
        molecule: 'MNameAndMetaSettings',
        isTemplate: true
      },
      {
        title: `${props?.isTemplate ? 'Template' : 'Program'} Checkout Qualifications`,
        molecule: 'MCheckoutQualificationSettings',
        isTemplate: true
      },
      {
        title: `Rewards and Credentials Management`,
        molecule: 'MRewardsAndCredentialsManagement',
        isTemplate: true
      },
      { title: 'Add Organizers', molecule: 'MAddOrganizers', isTemplate: false },
      { title: 'Clone Program', molecule: 'CloneProgram', isTemplate: false }
    ]);

    const menuListItems = ref(listItems);
    if (props.isTemplate) {
      menuListItems.value = listItems?.value?.filter(item => item.isTemplate);
    }

    const appendMolecule = val => {
      if (val === 'MAddOrganizers') {
        isAddNameAndMeta.value = false;
        isAddCheckoutQualification.value = false;
        isAddOrganizers.value = true;
        isAddRewardsAndCredentials.value = false;
      } else if (val === 'MCheckoutQualificationSettings') {
        isAddNameAndMeta.value = false;
        isAddCheckoutQualification.value = true;
        isAddOrganizers.value = false;
        isAddRewardsAndCredentials.value = false;
      } else if (val === 'MRewardsAndCredentialsManagement') {
        isAddNameAndMeta.value = false;
        isAddCheckoutQualification.value = false;
        isAddOrganizers.value = false;
        isAddRewardsAndCredentials.value = true;
      } else if (val === 'CloneProgram') {
        cloneModal.value = true;
      } else {
        isAddNameAndMeta.value = true;
        isAddCheckoutQualification.value = false;
        isAddOrganizers.value = false;
        isAddRewardsAndCredentials.value = false;
      }
    };
    if (props.openCheckoutView) {
      appendMolecule('MCheckoutQualificationSettings');
    }

    async function cloneProgram() {
      isCloning.value = true;
      const API_ENDPOINT = process.env.VUE_APP_CLONE_PROGRAM;

      const data = {
        owner_id: user.value?._id.toString(),
        program_id: programIdValue.value.toString()
      };
      try {
        const resp = await cloneProgramData(data);
        if (resp) {
          ctx.root.$router.push({
            path: `/guide/${resp.data._id.toString()}/0`,
            props: {
              programId: resp.data._id.toString()
            }
          });
          setTimeout(async () => {
            await props.fetchProgram();
            isCloning.value = false;
            cloneModal.value = false;
            dialog.value = false;
          }, 2000);
        }
      } catch (err) {
        console.log(err);
        // isCloningProgram.value = false;
        isCloning.value = false;
        cloneModal.value = false;
      }
    }
    return {
      dialog,
      menuListItems,
      isAddNameAndMeta,
      isAddCheckoutQualification,
      isAddOrganizers,
      isAddRewardsAndCredentials,
      appendMolecule,
      programIdValue,
      cloneModal,
      isCloning,
      cloneProgram
    };
  }
});
